import Standings from "./standings";


function App() {
  return (
    <div className="App">
     <Standings/>
    </div>
  );
}

export default App;
